<template>
  <div class="bg-white border-b border-gray-200 shadow">
    <div class="flex flex-wrap items-center px-4 py-2">
      <!-- logo -->
      <div class="mr-4">
        <sa-icon-button
          with-bg
          iconClass="text-xl bx bx-menu"
          @click.stop="onMenuClick"
        />
      </div>
      <div class="mr-4">
        <div
          @click="$router.push({ to: '/home' })"
          class="flex flex-row flex-wrap items-center space-x-1 no-underline"
        >
          <img
            src="@/assets/img/mini-responsive-samii.png"
            v-if="!logo"
            alt=""
            style="max-height: 40px; object-fit: contain"
            class="m-1"
          />
          <img
            :src="logo"
            v-if="logo"
            alt=""
            class="m-1"
            style="max-height: 45px; object-fit: contain"
          />
          <span class="font-bold text-gray-600 text-md font-headline">{{
            $store.state.business.name || "Fixme up"
          }}</span>
        </div>
      </div>

      <div v-if="isProMembership">
        <el-tooltip
          manual
          :value="visible"
          placement="bottom"
          effect="light"
          class="rounded"
          popper-class="business-offices"
        >
          <div slot="content">
            <div class="p-4">
              <div class="font-bold mb-2">
                <span class="text-xl">Sucursales</span>
              </div>
              <div class="my-4 overflow-y-auto h-40">
                <el-radio-group
                  v-model="currentOffice"
                  @change="handleOfficeChange"
                  class="flex flex-col space-y-4"
                >
                  <el-radio
                    v-if="
                      $store.state.account.accountType === AccountTypeEnum.Owner
                    "
                    value="all"
                    label="all"
                  >
                    GENERAL
                  </el-radio>
                  <el-radio
                    v-for="office in offices"
                    :key="office.id"
                    :value="office.id"
                    :label="office.id"
                  >
                    {{ office.name }}
                  </el-radio>
                </el-radio-group>
              </div>
              <div class="text-center">
                <el-button
                  @click="
                    $router.push({
                      name: 'administration.offices',
                      params: {
                        action: 'open-create-form',
                      },
                    })
                  "
                >
                  + Crear Sucursal
                </el-button>
              </div>
            </div>
          </div>
          <el-button @click="visible = !visible">
            <div class="inline-flex items-center">
              {{ currentOfficeName }}
              <i class="bx bx-chevron-down text-2xl"></i>
            </div>
          </el-button>
        </el-tooltip>
      </div>

      <div class="flex items-center justify-center flex-grow">
        <div class="w-4/5 sm:w-4/5 md:w-2/3 lg:w-3/5 xl:w-1/3">
          <el-input
            v-model="search"
            class="border rounded-md"
            @focus="
              search = '';
              orders = [];
              clients = [];
            "
            @input="querySearchAsync"
            placeholder="Buscar en Órdenes, Clientes, IMEI"
          >
          </el-input>
          <div
            class=""
            v-if="orders.length > 0 || clients.length > 0"
            style="
              position: fixed;
              z-index: 2;
              background-color: white;
              padding: 10px;
              width: 300px;
              border-color: rgb(139, 92, 246);
              border-width: 1px;
            "
          >
            <div style="max-height: 500px; overflow-y: scroll">
              <div>
                <h3><strong>Clientes</strong></h3>
                <div class="ml-3 pb-2 pt-2" v-if="clients.length === 0">
                  No hay resultados.
                </div>
                <div
                  v-for="client in clients"
                  v-bind:key="client.id"
                  class="ml-3 pb-2 pt-2 cursor-pointer"
                >
                  <a @click="handleSelectSearch(client, 'client')">{{
                    client?.fullName
                  }}</a>
                </div>
              </div>
              <div>
                <h3><strong>Ordenes</strong></h3>
                <div class="ml-3 pb-2 pt-2" v-if="orders.length === 0">
                  No hay resultados.
                </div>
                <div
                  v-for="order in orders"
                  v-bind:key="order.id"
                  class="ml-3 pb-2 pt-2 cursor-pointer"
                >
                  <a @click="handleSelectSearch(order, 'order')">{{
                    order.reference
                  }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- menu -->
      <ul class="flex flex-row items-center flex-shrink ml-auto">
        <quick-start-tip
          title="Acciones Rapidas"
          content="Con este boton tendras acceso rapido a las acciones
          mas comunes que puedes hacer en SAMII"
          placement="bottom"
          :bg-purple="$store.state.quickStart.step === 1"
          :rounded-full="$store.state.quickStart.step === 1"
          :value="$store.state.quickStart.step === 1"
          @onNextStep="handleNextStep"
        >
          <li v-if="!$store.state.account.membershipLimitReached">
            <el-dropdown trigger="click">
              <sa-icon-button
                id="quick-actions-menu"
                :iconClass="`text-4xl bx bx-plus-circle ${
                  $store.state.quickStart.step === 1
                    ? 'text-white'
                    : 'text-purple-500'
                }`"
              />
              <el-dropdown-menu slot="dropdown">
                <quick-start-tip
                  title="Crear Orden"
                  content="Da click en la opcion 'Crear orden' para acceder de manera rapida
                  al formulario de las ordenes"
                  placement="left-start"
                  :bg-purple="$store.state.quickStart.step === 2"
                  :value="$store.state.quickStart.step === 2"
                  @onNextStep="handleNextStep"
                >
                  <el-dropdown-item>
                    <el-button
                      class="text-gray-700"
                      :class="{
                        'hover:text-purple-500':
                          $store.state.quickStart.step === 0,
                      }"
                      type="text"
                      :disabled="
                        $store.state.quickStart.step !== 0 ||
                        !$store.state.account.canCreateOrders
                      "
                      @click="handleCreateOrderClick"
                    >
                      <span
                        class="flex items-center"
                        :class="{
                          'text-white': $store.state.quickStart.step === 2,
                        }"
                      >
                        <i class="text-xl bx bx-task"></i>
                        Crear Orden
                      </span>
                    </el-button>
                  </el-dropdown-item>
                </quick-start-tip>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    class="text-gray-700 hover:text-purple-500"
                    :disabled="$store.state.quickStart.step !== 0"
                    @click="handleCreateProductClick"
                  >
                    <span class="flex items-center">
                      <i class="text-xl bx bxs-package"></i>
                      Agregar Producto
                    </span>
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    class="text-gray-700 hover:text-purple-500"
                    :disabled="$store.state.quickStart.step !== 0"
                    @click="handleCreateClientClick"
                  >
                    <span class="flex items-center">
                      <i class="text-xl bx bx-user"></i>
                      Crear Cliente
                    </span>
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    class="text-gray-700 hover:text-purple-500"
                    :disabled="$store.state.quickStart.step !== 0"
                    @click="handleCreateSupplierClick"
                  >
                    <span class="flex items-center">
                      <i class="text-xl bx bxs-truck"></i>
                      Crear Proveedor
                    </span>
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button
                    type="text"
                    class="text-gray-700"
                    :disabled="$store.state.quickStart.step !== 0"
                  >
                    <span class="flex items-center">
                      <i
                        class="text-xl bx bx-purchase-tag-alt"
                        @click="createExpense"
                      ></i>
                      <span @click="createExpense">Crear Gastos</span
                      ><pro-link v-if="!isProMembership" />
                    </span>
                  </el-button>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </quick-start-tip>
        <li
          v-if="
            !$store.state.account.membershipLimitReached &&
            $store.state.business.name
          "
          class="flex items-center"
        >
          <el-popover
            placement="bottom"
            width="500"
            popper-class="notifications-popper"
          >
            <notifications-mini-list />
            <el-button
              slot="reference"
              type="text"
              class="w-full p-0 text-gray-500 cursor-pointer hover:bg-gray-200 rounded-md"
            >
              <span class="flex items-center p-2 space-x-2 relative">
                <i class="text-xl bx bx-bell"></i>
                <span
                  v-if="hasNotifications"
                  class="font-semibold absolute top-1 right-1"
                >
                  <i class="text-xs text-red-400 bx bxs-circle"></i>
                </span>
              </span>
            </el-button>
          </el-popover>
        </li>
        <li>
          <el-dropdown trigger="click">
            <div
              class="p-2 px-3 text-gray-700 rounded el-dropdown-link hover:bg-gray-200"
            >
              <span class="font-bold">
                {{ fullname }} <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-if="$store.state.account.accountType !== 1">
                <el-button
                  class="text-gray-700 hover:text-purple-500"
                  type="text"
                  @click="navigate('account')"
                >
                  <span class="flex items-center">
                    <i class="text-xl bx bxs-user-circle"></i>
                    Configurar mi cuenta
                  </span>
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item v-if="$store.state.account.accountType === 1">
                <el-button
                  type="text"
                  class="text-gray-700 hover:text-purple-500"
                  @click="navigate('business')"
                >
                  <span class="flex items-center">
                    <i class="text-xl bx bxs-cog"></i>
                    Administrar mi negocio
                  </span>
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item divided>
                <el-button
                  class="text-gray-700 hover:text-purple-500"
                  type="text"
                  @click="logout()"
                >
                  <span class="flex items-center">
                    <i class="text-xl bx bx-log-out-circle"></i>
                    Cerrar sesión
                  </span>
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
        <li v-if="isWebhookEnabledForAccount">
          <el-button @click="handleWebhook"> Webhook </el-button>
        </li>
      </ul>
    </div>
  </div>
</template>
<style>
.notifications-popper {
  z-index: 999 !important;
}

.business-offices {
  width: 250px;
}
</style>
<script>
import { AccountTypeEnum, EnabledWebhookForAccountId } from "@/constants";
import SignService from "@/services/SignService";
import AdminService from "@/services/AdminService";
import OrderService from "@/services/OrderService";
import BusinessService from "@/services/BusinessService";
import NotificationService from "@/services/NotificationService";
import BusinessGetters from "@/store/modules/business/getters";
import { QUICK_START_STORE } from "@/store/modules/quick-start/mutation-types";
import { BUSINESS_READ } from "@/store/modules/business/mutation-types";
import { NOTIFICATIONS_STORE } from "@/store/modules/notifications/mutation-types";
import ExpenseService from "@/components/dialogs/Expense/service";
import services from "./services/TheHeaderAppServices";

export default {
  name: "TheHeaderApp",
  props: {
    onMenuClick: Function,
  },
  components: {
    ProLink: () => import("@/components/atoms/SaProLink.vue"),
    NotificationsMiniList: () => import("@/views/app/notifications/MiniList"),
  },
  data() {
    return {
      AccountTypeEnum,
      isProMembership:
        this.$store.state.account.membershipType === 2 ||
        this.$store.state.account.membershipType === 3,
      orders: [],
      clients: [],
      search: "",
      timeout: null,
      visible: false,
      offices: this.$store.state.business.offices.reduce((offices, office) => {
        // Check if I'm not an Owner
        if (this.$store.state.account.accountType !== AccountTypeEnum.Owner) {
          // If I'm not an Owner, check if I'm in an office
          if (
            office.employees.some(
              (employee) => employee.id === this.$store.state.account.id
            )
          ) {
            return [...offices, office];
          }
          return offices;
        }
        return [...offices, office];
      }, []),
    };
  },
  computed: {
    email() {
      return this.$store.state.account.email;
    },
    fullname() {
      return this.$store.state.account.fullname;
    },
    businessName() {
      return this.$store.state.account.businessName;
    },
    logo() {
      return BusinessGetters.logo();
    },
    hasNotifications() {
      return !!this.$store.state.notifications.entities.filter(
        (notification) => !notification.read
      ).length;
    },
    currentOffice: {
      get() {
        return this.$store.state.business.id ?? "all";
      },
      set(officeId) {
        if (officeId === "all") {
          this.$store.dispatch(BUSINESS_READ, {
            businessId: this.$store.state.business.businessId,
          });
          services.command.onCommand(services.commands.onBusinessChange);
          this.$store.dispatch(NOTIFICATIONS_STORE, []);
          return;
        }
        const selectedOffice = this.$store.state.business.offices.find(
          (office) => office.id === officeId
        );
        this.$store.dispatch(BUSINESS_READ, selectedOffice);
        NotificationService.get({ businessInfoId: selectedOffice.id });
        services.command.onCommand(services.commands.onBusinessChange);
      },
    },
    currentOfficeName() {
      if (this.$store.state.business.id) {
        return `Sucursal ${this.$store.state.business.name}`;
      }
      return `General`;
    },
    isWebhookEnabledForAccount() {
      return EnabledWebhookForAccountId.includes(this.$store.state.account.id);
    },
  },
  methods: {
    handleCreateOrderClick() {
      if (this.$store.state.business.id) {
        this.$router.push({ name: "orders.create" });
      } else {
        this.$toastr.i(
          "Tienes que estar en una sucursal para poder crear ordenes"
        );
      }
    },
    handleCreateProductClick() {
      if (this.$store.state.business.id) {
        this.$router.push({ name: "products.create" });
      } else {
        this.$toastr.i(
          "Tienes que estar en una sucursal para poder crear productos"
        );
      }
    },
    handleCreateClientClick() {
      if (this.$store.state.business.id) {
        this.$router.push({ name: "clients.create" });
      } else {
        this.$toastr.i(
          "Tienes que estar en una sucursal para poder crear clientes"
        );
      }
    },
    handleCreateSupplierClick() {
      if (this.$store.state.business.id) {
        this.$router.push({ name: "suppliers.create" });
      } else {
        this.$toastr.i(
          "Tienes que estar en una sucursal para poder crear proveedores"
        );
      }
    },
    logout() {
      SignService.logout();
      this.$router.push({ name: "landing.index" });
    },
    navigate(current) {
      if (this.$store.state.account.membershipLimitReached) {
        this.$router.push({ name: "administration.membership" });
      } else {
        this.$router.push({ name: `administration.${current}` });
      }
    },
    blurSearch() {
      this.search = "";
      this.clients = [];
      this.orders = [];
    },
    async querySearchAsync(queryString) {
      if (queryString.length > 2) {
        const result = await AdminService.GlabalSearch.search(queryString);
        this.clients = result.clients;
        this.orders = result.orders;
      }
      if (queryString.length <= 2) {
        this.clients = [];
        this.orders = [];
      }
    },
    createFilter(queryString) {
      return (link) => {
        return (
          link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    handleSelectSearch(item, type) {
      let routeData = "";
      if (type === "order") {
        routeData = this.$router.resolve({
          name: "orders.read",
          params: { id: item.id },
        });
      }
      if (type === "client") {
        routeData = this.$router.resolve({
          name: "orders.index-filter",
          params: { clientid: item.id, client: item.fullName },
        });
      }
      this.blurSearch();
      window.open(routeData.href, "_blank");
    },
    async handleNextStep() {
      const step = this.$store.state.quickStart.step;
      await this.$store.dispatch(QUICK_START_STORE, { step: step + 1 });
      if (step === 1) {
        const quickActionsMenu = document.getElementById("quick-actions-menu");
        quickActionsMenu.click();
      }
      if (step === 2) {
        const { data: orders } = await OrderService.getAll({});
        if (!orders.length) {
          this.$toastr.e("No existen ordenes");
          return;
        }
        await this.$router.push({
          name: "orders.read",
          params: { id: orders[0].id, lockScreen: true, lockScroll: true },
        });
      }
    },
    createExpense() {
      if (!this.isProMembership) return;
      if (this.$store.state.business.id) {
        ExpenseService.show();
      } else {
        this.$toastr.i(
          "Tienes que estar en una sucursal para poder crear gastos"
        );
      }
    },
    handleOfficeChange() {
      this.visible = false;
    },
    async handleWebhook() {
      this.$confirm(`¿Seguro que deseas ejecutar el Webhook?`, {
        type: "warning",
        confirmButtonText: "OK",
        cancelButtonText: "Cancelar",
      }).then(async () => {
        this.$toastr.i("El webhook se esta ejecutando localmente");
        try {
          await BusinessService.webhook({
            businessId: this.$store.state.account.businessId,
          });
          this.$toastr.s("El webhook se ejecuto correctamente");
        } catch (error) {
          this.$toastr.e(
            "Algo salio mal, el webhook no se ejecuto correctamente"
          );
        }
      });
    },
  },
};
</script>
