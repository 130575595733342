export const Routes = {
  orders: { label: 'Órdenes', routePath: '/orders', iconClass: 'bx bx-task'  },
  products: { label: 'Inventario', routePath: '/products', iconClass: 'bx bxs-package' },
  clients: { label: 'Clientes', routePath: '/clients', iconClass: 'bx bx-user' },
  suppliers: { label: 'Proveedores', routePath: '/suppliers', iconClass: 'bx bxs-truck' },
  services: { label: 'Servicios', routePath: '/services', iconClass: 'bx bx-wrench' },
  expenses: { label: 'Gastos', routePath: '/expenses', iconClass: 'bx bx-purchase-tag-alt', validatePro:true },
  statistics: { label: 'Reportes', routePath: '/finances', iconClass: 'bx bx-stats' },
  courses: { label: 'Tutoriales', routePath: '/courses', iconClass: 'bx bx-play' },
};
