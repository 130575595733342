<template>
  <nav class="">
    <div class="flex flex-col w-full text-gray-500">
      <div>
        <ul
          class="flex flex-col flex-grow py-6 space-y-1"
          :class="{ disabled: disabled }"
        >
          <li
            id="home"
            class="flex-grow border-purple-500 cursor-pointer hover:bg-gray-100"
            :class="{
              'border-l-4 bg-gray-100 border-purple-500 text-purple-500':
                isCurrentRoute('/home'),
              disabled: disabled,
            }"
          >
            <a @click="navigate('/home')">
              <span
                class="flex items-center p-2 space-x-2"
                :class="collapsed ? 'justify-center' : ''"
              >
                <i class="text-xl bx bxs-dashboard"></i>
                <span v-if="!collapsed" class="font-semibold">Inicio</span>
              </span>
            </a>
          </li>
          <li v-if="!collapsed" id="sub-title">
            <span
              class="flex px-4 my-2 mt-4 text-sm font-bold text-gray-400 uppercase"
              >Mi taller</span
            >
          </li>
          <li
            class="flex-grow border-purple-500 hover:bg-gray-100 flex items-center"
            v-for="item in menu"
            :key="item.routePath"
            :class="{
              'border-l-4 bg-gray-100 border-purple-500': isCurrentRoute(
                item.routePath
              ),
              disabled: disabled,
              'items-center justify-center cursor-pointer':
                collapsed,
            }"
          >
            <a
              :class="{ disabled: disabled }"
              @click="navigate(item.routePath)"
            >
              <span
                class="flex items-center p-2 space-x-2"
                :class="collapsed ? 'justify-center' : ''"
              >
                <i class="text-xl" :class="item.iconClass"> </i>
                <span v-if="!collapsed" class="font-semibold"
                  >{{ item.label }}
                </span>
              </span>
              <span v-if="item.isNew" class="new-feature-icon">New</span>
            </a>
            <pro-link
              v-if="!collapsed && !isProMembership && item.validatePro"
            ></pro-link>
          </li>
          <li
            v-if="collapsed && $store.state.account.accountType === 1"
            class="flex-grow border-purple-500 cursor-pointer hover:bg-gray-100 hover:text-purple-500 fixed w-16 bottom-4"
            :class="{ disabled: disabled }"
          >
            <span class="flex items-center p-2 space-x-2 justify-center">
              <i class="text-xl bx bx-mail-send" />
            </span>
          </li>
        </ul>
      </div>
      <div
        v-if="!collapsed && $store.state.account.accountType === 1"
        class="fixed w-64 bottom-4"
      >
        <div
          class="flex items-center cursor-pointer"
          @click="
            navigate({
              name: 'administration.users',
            })
          "
        >
          <span
            class="w-full flex items-center p-2 space-x-2 hover:bg-gray-100 hover:text-purple-500"
            :class="{ 'justify-center': collapsed, disabled: disabled }"
          >
            <i class="text-xl bx bx-mail-send" />
            <span id="team-invite" class="font-bold">Invitar a mi equipo</span>
          </span>
        </div>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.disabled:not(#home) {
  cursor: not-allowed !important;
}
.disabled > li:not(#home):not(#sub-title),
.disabled > #team-invite {
  text-decoration: line-through;
}
.disabled:hover:not(#home) {
  color: inherit;
  background-color: inherit;
}
.new-feature-icon {
  width: 20px;
  height: 20px;
  background-color: #ff0000;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 20px;
  font-size: 10px;
}
</style>

<script>
import { Routes } from "./constants";

export default {
  name: "TheMenuApp",
  components: {
    ProLink: () => import("@/components/atoms/SaProLink.vue"),
  },
  props: {
    collapsed: Boolean,
    disabled: Boolean,
  },
  data() {
    const { canViewStatistics } = this.$store.state.account;
    const routes = [
      Routes.orders,
      Routes.products,
      Routes.clients,
      Routes.suppliers,
      Routes.services,
      Routes.expenses,
    ];
    if (canViewStatistics) routes.push(Routes.statistics);
    routes.push(Routes.courses);

    return {
      isProMembership: this.$store.state.account.membershipType === 2 || this.$store.state.account.membershipType === 3,
      menu: routes,
    };
  },
  methods: {
    isCurrentRoute(routePath) {
      return this.$router.currentRoute.path.includes(routePath);
    },
    navigate(path) {
      if (this.disabled) return;
      this.$router.push(path);
    },
  },
};
</script>
