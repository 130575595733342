<template>
  <div>
    <nav class="fixed top-0 z-50 w-full h-14">
      <the-header-app :onMenuClick="onMenuClick" />
    </nav>
    <div class="min-h-screen bg-gray-50 header-app-height-check">
      <aside
        class="fixed h-screen border-r border-gray-200"
        :class="collapsed ? 'w-16' : 'w-64'">
        <the-menu-app :collapsed="collapsed" :disabled="disabled" />
      </aside>
      <main class="mt-14" :class="collapsed ? 'ml-16' : 'ml-64'">
        <div class="text-gray-500 text-lg absolute pt-4 pb-0 pl-2" v-if="isProMembership">
          {{ office }}
        </div>
        <slot />
      </main>
    </div>
  </div>
</template>
<style scoped>
.header-app-height-check {
  margin-top: 11rem;
}
@media (min-width: 240px) {
  .header-app-height-check {
    margin-top: 8.5rem;
  }
}
@media (min-width: 410px) {
  .header-app-height-check {
    margin-top: 5.5rem;
  }
}
@media (min-width: 620px) {
  .header-app-height-check {
    margin-top: 0;
  }
}
</style>
<script>
import TheHeaderApp from '@/components/organisms/TheHeaderApp';
import TheMenuApp from '@/components/organisms/TheMenuApp';
import responsiveSize from '@/mixins/responsiveSize';

export default {
  mixins: [responsiveSize],
  components: {
    TheHeaderApp,
    TheMenuApp
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },

  data() {
    return {
      isProMembership: this.$store.state.account.membershipType === 2 || this.$store.state.account.membershipType === 3,
      collapsed: false,
    }
  },
  computed: {
    disabled() {
      return this.$store.state.account.membershipLimitReached;
    },
    office() {
      return this.$store.state.business.id ? `Sucursal ${ this.$store.state.business.name }` : `GENERAL`;
    }
  },
  methods: {
    resize() {
      const percentageWidth = this.getPercentageWidth();
      this.collapsed = percentageWidth === '90%';
    },
    onMenuClick() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>
